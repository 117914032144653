import { inject, Injectable, signal } from '@angular/core';
import {
	CourseSearchErrors,
	CourseSearchLocationGA,
	DegreeLevelLabel,
	Product,
} from '@uc/web/shared/data-models';
import { DataLayerService } from '../data-layer/data-layer.service';
import {
	abbreviateProduct,
	isUserToNumber,
	verifyCleso,
	verifyUgso,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class CourseGTMService {
	noSearchTermError = signal(false);
	private dataLayerSrv = inject(DataLayerService);

	private _isAutosuggest = false;
	private _searchLocation: CourseSearchLocationGA = 'Default';

	getAutosuggest() {
		const temp = this._isAutosuggest;
		this._isAutosuggest = false;
		return temp;
	}
	setAutosuggest(value: boolean) {
		this._isAutosuggest = value;
	}

	getSearchLocation() {
		const temp = this._searchLocation;
		this._searchLocation = 'Default';
		return temp;
	}
	setSearchLocation(value: CourseSearchLocationGA) {
		this._searchLocation = value;
	}

	/**
	 * search term needs formatting
	 * degree level will need to be adjusted based on 'undergraduate/ -> All Undergraduate
	 * also format case (see if Marius already has something in there)
	 */

	courseProfileClick(
		uniId: number,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_profile_click',
				university: uniId,
				universityName: uniName,
				course_name: courseName,
				product: abbreviateProduct(product),
				course_id: courseId,
				event: 'courseProfileClick',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	keywordImpression(
		componentName: string,
		uniId: number,
		uniName: string,
		isUser: boolean,
		subject: string,
		product: Product | '' = '',
	) {
		this.dataLayerSrv.push({
			eventName: 'keyword_impression',
			componentName: componentName,
			university: uniId,
			universityName: uniName,
			product: abbreviateProduct(product),
			user: isUserToNumber(isUser),
			subject_name: subject,
			event: 'keywordImpression',
		});
	}

	keywordClick(
		componentName: string,
		uniId: number,
		uniName: string,
		isUser: boolean,
		subject: string,
		product: Product | '' = '',
	) {
		this.dataLayerSrv.push({
			eventName: 'keyword_click',
			componentName: componentName,
			university: uniId,
			universityName: uniName,
			product: abbreviateProduct(product),
			user: isUserToNumber(isUser),
			subject_name: subject,
			event: 'keywordClick',
		});
	}

	courseImpression(
		uniId: number,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_impression',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				course_name: courseName,
				course_id: courseId,
				event: 'courseImpression',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/*
  		User views the screen for a course profile
	*/
	courseProfileView(
		uniId: number | undefined,
		uniName: string,
		courseName: string,
		courseId: number,
		ugSearchOrderPosition: number | null,
		cleSearchOrderPosition: number | null,
		product: Product | '' = '',
	) {
		const ugso = verifyUgso(ugSearchOrderPosition, product);
		const cleso = verifyCleso(cleSearchOrderPosition, product);
		const trackingData = Object.assign(
			{
				eventName: 'course_profile_view',
				university: uniId,
				universityName: uniName,
				product: abbreviateProduct(product),
				course_name: courseName,
				course_id: courseId,
				event: 'courseProfileView',
			},
			ugso ? { ugso, cleso: 'n/a' } : {},
			cleso ? { cleso, ugso: 'n/a' } : {},
		);

		this.dataLayerSrv.push(trackingData);
	}

	/**
	 * When user clicks search courses button in statements header
	 */

	searchCourseClick() {
		this.dataLayerSrv.push({
			eventName: 'search_course_click',
			event: 'searchCourseClick',
		});
	}

	/**
	 * When user clicks view more courses button
	 */

	viewMore(uniId: number, uniName: string, searchTerm: string) {
		this.dataLayerSrv.push({
			eventName: 'see_more_courses_button_click',
			university: uniId,
			universityName: uniName,
			searchQuery: searchTerm,
			event: 'viewMore',
		});
	}

	// when user lands on the course search page
	courseSearch(
		searchTerm: string,
		searchLocation: CourseSearchLocationGA,
		heCosSubject: boolean,
		degreeLevel: DegreeLevelLabel,
		errorEncountered: CourseSearchErrors | boolean,
		autoSuggest: boolean,
	) {
		this.dataLayerSrv.push({
			eventName: 'course_search',
			searchTerm: searchTerm.split('-').join(' '),
			heCosSubject: heCosSubject ? searchTerm : false,
			searchLocation,
			degreeLevel,
			errorEncountered,
			autoSuggest,
			event: 'courseSearch',
		});
	}
}
